<template>
  <div class="warp">
    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase02.png" alt="" />
        <div class="image-introduction">
          <div class="title">精诚CRO</div>
          <div class="introduction">20年CRO行业经验积累 立足中国 放眼世界</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          精诚CRO是一家活跃在医药研发及医药市场研究领域的专业服务提供机构，国内领先的CRO公司。公司成立于2001年，是中国最早成立的CRO公司之一。总部位于北京，并在全国14个地区设有办事处，在美国、英国、德国、日本、瑞士设有联络处。公司多年连续承接国家多个重要课题研究项目，并建设和管理国家重大疾病样本库。
        </p>
        <p>
          精诚CRO立足中国，放眼全球，聚焦在传统医药及新兴生物医药技术产业的研发过程和医药市场研究领域，为客户提供专业化的技术解决方案。公司主要业务范围包括医药产品临床试验的组织和运作管理，药品、器械、保健品注册及市场准入服务，医药行业市场研究与咨询。合作客户包括大型跨国制药公司、国内研发型制药企业、相关政府机构以及专业学术团体等。
        </p>
      </div>
    </div>
    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase03.png" alt="" />
        <div class="image-introduction">
          <div class="title">石四药</div>
          <div class="introduction">科技引领绿色制药 创造健康美好生活</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          石家庄四药有限公司始建于1948年，历经七十余年的历史变迁，现已发展成为大型综合制药集团，形成以化学制剂、原料药、中药制剂和医用包材为主，产业链较为齐全的新型产业发展格局。企业拥有国家企业技术中心等多个高水平创新平台，主导产品大容量注射剂年产达20亿瓶（袋），是国内重要的注射剂生产、研发和出口龙头企业，跻身中国医药工业百强企业、中国化学制药行业制剂出口型优秀企业行列。2007年，公司在香港主板上市，是我省医药产业重要的海外融资窗口，市值达200多亿港元。
        </p>
      </div>
    </div>

    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase04.png" alt="" />
        <div class="image-introduction">
          <div class="title">华润医药集团有限公司</div>
          <div class="introduction">引领商业进步，共创美好生活</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          华润的前身是于1938年在香港成立的“联和行”。1948年联和进出口公司改组更名为华润公司。1952年隶属关系由中共中央办公厅转为中央贸易部（现为商务部）。1983年改组成立华润（集团）有限公司。1999年12月，与外经贸部脱钩，列为中央管理。2003年归属国务院国资委直接监管，被列为国有重点骨干企业。1953年华润公司成为中国各进出口公司在香港总代理。在这一时期，华润的主要任务是组织对港出口，为内地进口重要物资，保证香港市场供应，贸易额曾占全国外贸总额的三分之一。1983年华润集团成立后，因应外贸体制改革的形势，企业逐渐从综合性贸易公司转型为以实业为核心的多元化控股企业集团。
        </p>
      </div>
    </div>

    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase05.png" alt="" />
        <div class="image-introduction">
          <div class="title">葵花药业</div>
          <div class="introduction">有太阳的地方就有葵花，有葵花的地方就有阳光</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          葵花药业，即葵花药业集团有限公司，是以黑龙江葵花药业股份有限公司为核心的民营股份制企业，是1998年4月由濒临破产的原国有五常制药厂改制而成的黑龙江葵花药业股份有限公司为核心企业。企业可生产10个剂型的近400个品种的“准”字号中成药，形成了以独家研制、首家生产的护肝片、胃康灵胶囊为龙头，以康妇消炎栓、天菊脑安胶囊、小儿肺热咳喘口服液为骨干的葵花牌药品系列，产品畅销全国。
        </p>
      </div>
    </div>

    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase06.png" alt="" />
        <div class="image-introduction">
          <div class="title">华亘控股</div>
          <div class="introduction">发展民族医疗科技，以服务大众健康为己任</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          北京华亘安邦科技有限公司成立于2002年7月，创始人把“发展民族医疗科技、以服务大众健康为己任”作为自己的经营宗旨，致力于高科技医疗产品的研发、制造、销售和服务。公司总部注册于北京中关村科技园区，在北京、广州和泰州建有研究开发及生产基地，是高新技术企业。华亘创始人在90年代就开始研发碳13尿素呼气检测系统，并一直致力于幽门螺杆菌防治、胃早癌防治的推广，时至今日，已经用该产品为国人提供了超过千万人次的服务，为中国幽门螺杆菌防治事业做出了巨大的贡献。不仅如此，华亘的碳13尿素呼气检测系统在世界范围内，为全球70多个国家地区的人民提供幽门螺杆菌检测服务。华亘立足消化科，还开发和代理了更多消化科的优质诊疗产品，例如，来自韩国MiroCam胶囊式内窥镜、时间分辨荧光免疫技术的PGⅠ
        </p>
      </div>
    </div>

    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase07.png" alt="" />
        <div class="image-introduction">
          <div class="title">先声药业集团有限公司</div>
          <div class="introduction">让患者早日用上更有效药物</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          先声药业是一家正在快速向创新与研发驱动转型的制药公司，获科技部批准建设“转化医学与创新药物国家重点实验室”。公司聚焦肿瘤、中枢神经和自身免疫三大疾病领域，致力于「让患者早日用上更有效药物」。凭借优异的研发与商业化能力，主要产品在中国保持领先的市场份额。先声药业秉持开放式创新的研发策略，与多家跨国公司和生物技术企业成为战略合作伙伴，促进全球生命科学成果在中国的价值实现。同时，先声药业连续多年位居「中国创新力医药企业十强」和「中国制药工业百强」。
        </p>
      </div>
    </div>

    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase08.png" alt="" />
        <div class="image-introduction">
          <div class="title">平光制药集团</div>
          <div class="introduction">为人类健康做贡献</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          平光制药股份有限公司（Pingguang pharmaceutical Limited by Share Ltd，简称平光制药），创立于1998年，公司总部位于安徽省合肥市双凤开发区，是集药品研发、制造和销售为一体的高新技术企业,是中国先进的心脑血管领域创新型制药企业。公司占地面积380亩，员工560人，其中大专以上文化程度占76%以上。平光制药旗下拥有5家生产企业：江苏平光制药有限责任公司、江苏平光（焦作)有限公司、江苏平光信谊（焦作)中药有限公司、辽宁玉皇药业有限公司；2家医药流通企业：合肥平光医药有限公司、焦作平光联邦医药有限公司。分别在合肥、沈阳、徐州、焦作建立了四个现代化的药品生产基地。
        </p>
      </div>
    </div>

    <div class="CRO">
      <div class="CRO-image">
        <img src="@/assets/images/customercase09.png" alt="" />
        <div class="image-introduction">
          <div class="title">安徽贝克联合制药有限公司</div>
          <div class="introduction">让乙肝、艾滋病毒远离人类</div>
        </div>
      </div>
      <div class="CRO-introduction">
        <p>
          安徽贝克联合制药有限公司坐落于有着“华东药都”之称的全国著名医药集散中心—安徽太和，是国家火炬计划重点高新技术企业、国家知识产权示范企业、国家专精特新“小巨人”企业、“质量中国”首批中国质量诚信企业、2018 年度中国医药国际化百强企业-最具成长力企业、改革开放40周年全国药品交易会标杆企业。自主创新是安徽贝克持续发展的战略支撑和不竭源泉，公司专注于艾滋病药物、乙肝药物等抗病毒药物领域的研发和核心技术能力的构建，建有抗病毒药物安徽省重点实验室、安徽省手性药物工程技术研究中心、国家级博士后科研工作站、国家企业技术中心等高层次研发平台，共同构成公司自主创新发展体系。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomerCase'
}
</script>

<style lang="less" scoped>
.warp {
  width: 100%;
  margin-top: 25px;
}
.CRO {
  width: 315px;
  margin: 0 auto;
  .CRO-image {
    width: 100%;
    height: 171px;
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .image-introduction {
      width: 100%;
      position: absolute;
      bottom: 5px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        // width: 70px;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
      }
      .introduction {
        // flex: 1;
        margin-right: 10px;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
    }
  }
  .CRO-introduction {
    width: 315px;
    font-size: 10px;
    font-weight: 400;
    color: #6e6e6f;
    p {
      margin: 15px 0;
    }
  }
}
</style>
